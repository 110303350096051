import React from 'react';
import styled from 'styled-components';

const ToolSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4px;
`;

const ToolPropsOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ToolPropsContainer = styled.div`
  display: block;
  padding: 15px 0px 17px 21px;
  margin: 0 16px 0 11px;
  border: solid 1px #d9d9d9;

  @media (max-width: 1024px) {
    border: none;
    padding: 15px 0px 17px 0px;
    margin: 0 15px 0 11px;
  }
`;

const FeatureNameAndValueRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: Univers;
  font-size: 14px;

  @media (max-width: 1024px) {
    flex-direction: column;
    font-size: 16px;
  }
`;

const ToolFeatureNameColumn = styled.div`
  width: 250px;
  text-align: left;
  margin-right: 13px;
  font-weight: 500;
  padding: 5px 0;
`;

const ToolFeatureValueColumn = styled.div`
  text-align: left;
  padding: 5px 0;
`;

const StyledAnchor = styled.a`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3374b7;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const ToolDocumentsWrapper = styled.div`
  margin-top: ${(props) => (props.$isExpanded ? '35px' : '50px')};
  margin-bottom: 30px;
`;

const PageTitleText = styled.h1`
  position: relative;
  font-family: Univers;
  font-size: 28px;
  font-weight: 500;
  color: var(--color-gray1);
  padding: 8px 0 0 11px;
  margin: 0;
`;

const ToolDocuments = ({ isExpanded, pdfUrl, documentsData }) => {
  if (!pdfUrl && (!documentsData || documentsData.length === 0)) {
    return (
      <ToolDocumentsWrapper $isExpanded={isExpanded}>
        <PageTitleText>Documents</PageTitleText>
        <ToolSummaryContainer>
          <ToolPropsOuterContainer>
            <ToolPropsContainer>
              <a>No documents found</a>
            </ToolPropsContainer>
          </ToolPropsOuterContainer>
        </ToolSummaryContainer>
      </ToolDocumentsWrapper>
    );
  }

  return (
    <ToolDocumentsWrapper $isExpanded={isExpanded}>
      <PageTitleText>Documents</PageTitleText>
      <ToolSummaryContainer>
        <ToolPropsOuterContainer>
          <ToolPropsContainer>
            {!!pdfUrl && (
              <FeatureNameAndValueRow>
                <ToolFeatureNameColumn>BDMI</ToolFeatureNameColumn>
                <ToolFeatureValueColumn>
                  <StyledAnchor
                    href={pdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {pdfUrl.replace(/.*\//, '')}
                  </StyledAnchor>
                </ToolFeatureValueColumn>
              </FeatureNameAndValueRow>
            )}

            {documentsData &&
              documentsData.map((doc) => (
                <FeatureNameAndValueRow key={doc.title + 1}>
                  <ToolFeatureNameColumn>{doc.title}</ToolFeatureNameColumn>
                  <ToolFeatureValueColumn>
                    <StyledAnchor
                      href={doc.cdnUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {decodeURIComponent(doc.cdnUrl.replace(/.*\//, ''))}
                    </StyledAnchor>
                  </ToolFeatureValueColumn>
                </FeatureNameAndValueRow>
              ))}
          </ToolPropsContainer>
        </ToolPropsOuterContainer>
      </ToolSummaryContainer>
    </ToolDocumentsWrapper>
  );
};

export default ToolDocuments;
