import React, { useContext, useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { appInsights } from '../../appInsights.js';
import styled from 'styled-components';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ErrorModalContext } from '../../Modals/ErrorModal';
import ErrorModal from '../../Modals/ErrorModal.jsx';
import { useOktaAuthContext } from '../../OktaAuthContext.js';
import { useOktaAuth } from '@okta/okta-react';

import FavoritesToolRow from './FavoritesToolRow';
import DataFetchErrorPlaceholder from '../../shared/DataFetchErrorPlaceholder.jsx';

import {
  fetchFavorites,
  removeItemFromFavorites,
} from '../../backend/favorites.js';

const SkeletonLoaderContainer = styled.div`
  margin-left: 8px;
  margin-right: 12px;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

const SkeletonRow = styled(Skeleton)`
  margin-bottom: 10px;
`;

const FavoritesMainContainer = styled.div`
  background-color: var(--color-gray6);
  width: 100%;
  min-height: calc(100vh - 150px);
  padding-top: 12px;
  padding-bottom: 20px;

  @media (max-width: 1024px) {
    padding: 0;
    background-color: #fff;
  }
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const FavoritesSubContainer = styled.div`
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  min-height: 500px;
  font-family: Univers;
  margin-left: 112px;
  margin-right: 112px;
  background-color: white;
  pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.$isDisabled ? '0.4' : '1')};

  @media (max-width: 1024px) {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 0;
    padding-right: 0;
  }
`;
const FavoritesTitle = styled.div`
  color: var(--color-gray1);
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0px 18px 20px;
  @media (max-width: 1024px) {
    font-size: 22px;
    margin: 14px 0px 12px 0px;
    width: 100%;
  }
`;

const EmptyStateContainer = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray3);
  font-size: 28px;
  font-weight: 500;
  text-align: center;
`;

const FavoritesPage = () => {
  const [, setError] = useContext(ErrorModalContext);
  const [itemBeingDeleted, setItemBeingDeleted] = useState('');
  const { openModal } = useOktaAuthContext();
  const { authState } = useOktaAuth();
  const [showFetchDataErrorScreen, setShowFetchDataErrorScreen] =
    useState(false);

  useEffect(() => {
    !authState?.isAuthenticated && openModal('/favorites');
  }, [authState?.isAuthenticated]);

  useEffect(() => {
    const trackViewChange = () => {
      appInsights.trackPageView({ name: location.pathname });
    };
    trackViewChange();
  }, [location.pathname]);

  useEffect(() => {
    document.title = 'Favorites';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Halliburton user favorites page';

    document.head.appendChild(metaDescription);

    return () => {
      document.title = '';
      document.head.removeChild(metaDescription);
    };
  }, []);

  const {
    data: fetchedFavoritesData,
    isLoading: isFavoritesDataLoading,
    refetch,
  } = useQuery('fetchFavorites', fetchFavorites, {
    onError: (error) => {
      const errorCode = error.message.split('/');
      if (error.constructor.name === 'TypeError') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'Network connection lost. Please check your network connection and try again.',
        });
        setShowFetchDataErrorScreen(true);
      } else if (errorCode[1] !== '401') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
          errorCode: `#FA-1-${errorCode[1]}`,
        });
      }
    },
  });

  const {
    mutate: removeFromFavorites,
    isLoading: isLoadingRemoveItemsFromFavorites,
  } = useMutation(
    (data) => removeItemFromFavorites(data.SapNumber.toString()),
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          openModal();
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FA-3-${errorCode[1]}`,
          });
        }
      },
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleRemoveFromFavorites = (sapNumber) => {
    removeFromFavorites({ SapNumber: sapNumber });
    setItemBeingDeleted(sapNumber);
  };

  return (
    <FavoritesMainContainer>
      <FavoritesSubContainer>
        {showFetchDataErrorScreen ? (
          <CenterDiv>
            <DataFetchErrorPlaceholder />
          </CenterDiv>
        ) : (
          <>
            <FavoritesTitle>Favorites</FavoritesTitle>
            {isFavoritesDataLoading ? (
              <SkeletonLoaderContainer>
                <SkeletonRow height={150} width={'100%'} count={7} />
              </SkeletonLoaderContainer>
            ) : fetchedFavoritesData?.length > 0 ? (
              fetchedFavoritesData?.map((favorite, index) => (
                <FavoritesToolRow
                  key={favorite.SapNumber}
                  favoritesData={favorite}
                  sapNumber={favorite.SapNumber}
                  isLoadingRemoveItemsFromFavorites={
                    isLoadingRemoveItemsFromFavorites &&
                    favorite.SapNumber === itemBeingDeleted
                  }
                  removeFromFavorites={() =>
                    handleRemoveFromFavorites(favorite.SapNumber)
                  }
                  isLastItem={index === fetchedFavoritesData.length - 1}
                />
              ))
            ) : (
              <EmptyStateContainer>
                {authState?.isAuthenticated
                  ? 'No Favorites Selected'
                  : 'Please sign into your account to access this feature'}{' '}
              </EmptyStateContainer>
            )}
          </>
        )}
      </FavoritesSubContainer>
      <ErrorModal />
    </FavoritesMainContainer>
  );
};

export default FavoritesPage;
