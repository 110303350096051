import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import SearchContext from '../SearchContext.js';

import SearchResultsHeader from './SearchResultsHeader.jsx';
import SearchResultsToolRow from './SearchResultsToolRow.jsx';
import SearchResultsPagination from './SearchResultsPagination.jsx';
import CompareToolsButton from './CompareToolsButton.jsx';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ErrorModalContext } from '../../../Modals/ErrorModal';
import ApplicationContext from '../../../ApplicationContext.js';

import ErrorModal from '../../../Modals/ErrorModal.jsx';

import { fetchFavorites } from '../../../backend/favorites.js';

import { Filter } from 'react-feather';

const MainContainer = styled.div`
  font-family: Univers;
  color: var(--color-gray1);
`;

const FilterResultsButton = styled.button`
  all: unset;
  height: 21px;
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-primary);
  display: flex;
  p {
    margin: 0;
    align-self: center;
  }
  @media (min-width: 901px) {
    display: none;
  }
`;

const SkeletonLoaderStyled = styled(Skeleton)`
  margin-top: 30px;
  margin-bottom: 10px;
`;

const SkeletonLoaderSmall = styled(Skeleton)`
  margin-bottom: 5px;
`;

const SkeletonLoaderRow = styled(Skeleton)`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const FilterAndCompareButtonContainer = styled.div`
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchResults = ({
  isLoading,
  resultsPerPage,
  searchData,
  rangeStart,
  rangeEnd,
  oktaRedirectOnLoginTo,
}) => {
  const { searchTerm, handleMobileFilterIconClick } = useContext(SearchContext);

  const { classType } = useContext(ApplicationContext);

  const [, setError] = useContext(ErrorModalContext);

  const { data: favoritesData, refetch } = useQuery(
    'fetchFavorites',
    fetchFavorites,
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] !== '401') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FA-1-${errorCode[1]}`,
          });
        }
      },
    }
  );

  const documentHeight = Math.max(
    document.documentElement.scrollHeight,
    document.body.scrollHeight
  );

  const numberOfRowsToFit = Math.floor(documentHeight / 150);

  const renderSkeletonRows = (numberOfRows) => {
    const divArray = Array.from({ length: numberOfRows });
    return (
      <div id="container">
        {divArray.map((_, index) => (
          <SkeletonLoaderRow key={index} height={150} width={'100%'} />
        ))}
      </div>
    );
  };

  const [comparisonArr, setComparisonArr] = useState([]);
  const [toolComparison, setToolComparison] = useState(false);

  const canCompare =
    (comparisonArr.length >= 2 && comparisonArr.length < 5) ||
    toolComparison === false;

  const handleCompareClick = () => {
    if (canCompare) {
      navigate('/compare-tools', { state: { selectedTools: comparisonArr } });
    }
  };

  const isMobile = classType === 'compact';

  return (
    <MainContainer>
      <ErrorModal />

      {isLoading ? (
        <>
          <SkeletonLoaderStyled width={230} height={40} />
          <SkeletonLoaderSmall width={340} height={20} />
          <SkeletonLoaderSmall width={280} height={20} />
          {renderSkeletonRows(numberOfRowsToFit)}
        </>
      ) : (
        <>
          <FilterAndCompareButtonContainer>
            <FilterResultsButton onClick={() => handleMobileFilterIconClick()}>
              <Filter
                color="var(--color-primary)"
                fill="var(--color-primary)"
                height={19}
                strokeWidth={0}
              />
              <p>Results</p>
            </FilterResultsButton>

            {isMobile && (
              <CompareToolsButton
                setComparisonArr={setComparisonArr}
                isDisplay={searchData?.length > 0}
                canCompare={canCompare}
                toolComparison={toolComparison}
                enableToolComparison={setToolComparison}
                tools={comparisonArr}
              />
            )}
          </FilterAndCompareButtonContainer>
          <SearchResultsHeader
            searchTerm={searchTerm}
            isDisplayCompare={searchData?.length > 0}
            urlRange={resultsPerPage}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            tools={comparisonArr}
            setComparisonArr={setComparisonArr}
            canCompare={canCompare}
            toolComparison={toolComparison}
            enableToolComparison={setToolComparison}
          />

          {searchData &&
            searchData?.map((tool) => (
              <SearchResultsToolRow
                key={tool.SapNumber}
                searchData={tool}
                comparisonArr={comparisonArr}
                setComparisonArr={setComparisonArr}
                isFavorited={favoritesData?.some(
                  (favoritedItem) =>
                    favoritedItem.SapNumber === tool.SapNumber.toString()
                )}
                toolComparisonActive={toolComparison}
                refetchFavoritesData={refetch}
                oktaRedirectOnLoginTo={oktaRedirectOnLoginTo}
              />
            ))}
          {searchData?.length !== 0 && <SearchResultsPagination />}
        </>
      )}
    </MainContainer>
  );
};

export default SearchResults;
