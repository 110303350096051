import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ApplicationContext from '../../ApplicationContext.js';
import UpdateItemInCartForm from '../ToolDetailsPage/UpdateItemInCartForm.jsx';
import { ChevronRight, ChevronDown } from 'react-feather';

const LineItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 8px;
  border: 1px solid #ced6df;
  background: white;
  margin: 3px 14px;

  @media (max-width: 1024px) {
    border: 0;
    border-top: 1px solid #979fad;
    margin: 0;
    padding-left: 5%;
    padding-right: 5%;
    &:nth-last-child(2) {
      border-bottom: ${(props) =>
        props.$myCart ? '1px solid #979fad' : 'none'};
    }
    @media (max-width: 500px) {
      padding-left: 2%;
      padding-right: 2%;
    }
  }
`;

const DefaultContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSideContainerButton = styled.button`
  font-family: Univers;
  border: none;
  background: none;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  width: 100%;
`;

const LeftSideContainerMobile = styled.div`
  word-wrap: break-word;
  display: flex;
  align-items: center;
  width: ${(props) => (props.$myCart ? '80%' : '65%')};
  @media (max-width: 500px) {
    width: ${(props) => (props.$myCart ? '65%' : '50%')};
  }
`;

const ChevronAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

const NameAndSapContainer = styled.div`
  width: 100%;
`;

const LineItemName = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: var(--color-gray2);
  padding-left: 4px;
  text-align: left;
  @media (max-width: 1024px) {
    color: var(--color-gray1);
    width: 95%;
    padding-left: 0;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 500px) {
    width: 75%;
  }
`;

const ProductMaterialNumber = styled.span`
  color: #6c727d;
  font-weight: 300;
  font-size: 16px;
  margin-right: 12px;
  width: 25%;
  text-align: left;
  @media (max-width: 1024px) {
    color: #6c727d;
  }
`;

const RightSideContainer = styled.div`
  width: 40%;
  padding-left: 10px;
  display: flex;
  align-self: end;
  align-items: center;
  justify-content: end;
  @media (max-width: 1024px) {
    width: ${(props) => (props.$myCart ? '35%' : '30%')};
  }
  @media (max-width: 800px) {
    width: 25%;
  }
  @media (max-width: 500px) {
    width: ${(props) => (props.$myCart ? '35%' : '50%')};
  }
`;

const ExpandedItemTrayContainer = styled.div`
  display: flex;
  padding: 4px 0px 4px 48px;

  @media (max-width: 1024px) {
    padding: 4px 0px 4px 30px;
  }
`;

const ExpandedItemTrayMoreInfoButton = styled.button`
  font-family: Univers;
  appearance: none;
  border: none;
  background: none;
  color: #008185;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;

  @media (max-width: 1024px) {
    padding: 0;
    margin-top: 12px;
  }
`;

const PropertiesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PropertyContainer = styled.div`
  display: flex;
`;

const PropertyName = styled.span`
  color: var(--color-gray3);
  font-weight: 500;
  font-size: 16px;
  text-transform: lowercase;
  margin-right: 8px;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const PropertyValue = styled.span`
  color: var(--color-gray2);
  font-size: 16px;
  font-weight: 400;
  padding-right: 28px;
`;

const StyledChevronRight = styled(ChevronRight)`
  margin-top: 2px;
`;

const Chevron = styled.div`
  display: flex;
  border: none;
  background: none;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 0 6px 0 0;
  }
`;

const LineItem = ({
  productName,
  myCart,
  sapNumber,
  isShoppingCartView,
  quantity,
  productPropertyData,
  isSuggestedItems,
}) => {
  const { classType } = useContext(ApplicationContext);
  const navigate = useNavigate();

  const [expandRow, setExpandRow] = useState(false);

  const handleMoreInfoClick = () => {
    navigate(`/tool/${sapNumber}`);
  };

  return (
    <>
      <LineItemContainer $myCart={myCart}>
        <DefaultContainer>
          {classType !== 'compact' ? (
            <LeftSideContainerButton
              onClick={() => setExpandRow((prev) => !prev)}
              aria-label={expandRow ? 'expand row' : 'minimize row'}
            >
              <ChevronAndNameContainer>
                {expandRow ? (
                  <ChevronDown size={20} color={'var(--color-primary)'} />
                ) : (
                  <ChevronRight size={20} color={'var(--color-gray2)'} />
                )}
                <LineItemName>{productName}</LineItemName>
              </ChevronAndNameContainer>
              <ProductMaterialNumber>MAT# {sapNumber}</ProductMaterialNumber>
            </LeftSideContainerButton>
          ) : (
            <LeftSideContainerMobile
              $myCart={myCart}
              onClick={() => setExpandRow((prev) => !prev)}
              aria-label={expandRow ? 'expand row' : 'minimize row'}
            >
              <Chevron>
                {expandRow ? (
                  <ChevronDown size={24} />
                ) : (
                  <ChevronRight size={24} color={'var(--color-gray2)'} />
                )}
              </Chevron>
              <NameAndSapContainer>
                <LineItemName>{productName}</LineItemName>
                <ProductMaterialNumber>Mat# {sapNumber}</ProductMaterialNumber>
              </NameAndSapContainer>
            </LeftSideContainerMobile>
          )}

          <RightSideContainer $myCart={myCart}>
            <UpdateItemInCartForm
              toolData={{
                SapNumber: sapNumber,
                Name: productName,
                DescriptionLite: productPropertyData,
                Quantity: quantity,
              }}
              oktaRedirectOnLoginTo="/login"
              buttonHeight="40px"
              isShoppingCartView={isShoppingCartView}
              isSuggestedItems={isSuggestedItems}
            />
          </RightSideContainer>
        </DefaultContainer>
        {expandRow && (
          <>
            {classType !== 'compact' ? (
              <ExpandedItemTrayContainer>
                <ExpandedItemTrayMoreInfoButton onClick={handleMoreInfoClick}>
                  More Info <StyledChevronRight size={14} />{' '}
                </ExpandedItemTrayMoreInfoButton>
                <PropertiesContainer>
                  {productPropertyData.map((property) => (
                    <PropertyContainer key={property.DisplayName}>
                      <PropertyName>{property.DisplayName}</PropertyName>
                      <PropertyValue>{property.Value || 'N/A'}</PropertyValue>
                    </PropertyContainer>
                  ))}
                </PropertiesContainer>
              </ExpandedItemTrayContainer>
            ) : (
              <ExpandedItemTrayContainer>
                <PropertiesContainer>
                  {productPropertyData.map((property) => (
                    <PropertyContainer key={property.DisplayName}>
                      <PropertyName>{property.DisplayName}</PropertyName>
                      <PropertyValue>{property.Value || 'N/A'}</PropertyValue>
                    </PropertyContainer>
                  ))}
                  <ExpandedItemTrayMoreInfoButton onClick={handleMoreInfoClick}>
                    More Info <StyledChevronRight size={14} />{' '}
                  </ExpandedItemTrayMoreInfoButton>
                </PropertiesContainer>
              </ExpandedItemTrayContainer>
            )}
          </>
        )}
      </LineItemContainer>
    </>
  );
};

export default LineItem;
