import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useOktaAuth } from '@okta/okta-react';
import styled from 'styled-components';

import ApplicationContext from '../../../ApplicationContext.js';
import { ErrorModalContext } from '../../../Modals/ErrorModal.jsx';
import ErrorModal from '../../../Modals/ErrorModal.jsx';
import { useOktaAuthContext } from '../../../OktaAuthContext.js';

import { batchAddItemToShoppingCart } from '../../../backend/shoppingcart.js';

const QuickQuoteMenuContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 230px;
  background: white;
  top: 45px;
  left: 84%;
  transform: translateX(-50%);
  z-index: 7;
  padding: 24px 20px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  cursor: auto;

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid white;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: white;
    width: 0;
    height: 0;
  }
`;

const Title = styled.p`
  font-family: Univers;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray1);
  margin: 0;
  padding-bottom: 12px;
`;

const OuterContainer = styled.div`
  margin-bottom: 16px;
`;

const InputsContainer = styled.div`
  display: flex;
`;

const ItemMaterialNumberInput = styled.input`
  width: 150px;
  height: 28px;
  border: ${(props) =>
    props.$isInvalid
      ? 'solid 1px var(--color-primary)'
      : 'solid 1px var(--color-gray3)'};
  font-family: Univers;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: white;
  text-align: left;
  padding-left: 8px;
  color: ${(props) =>
    props.$isInvalid ? 'var(--color-primary)' : 'var(--color-gray1)'};
  font-style: ${(props) => (props.$isInvalid ? 'italic' : 'none')};
  &::placeholder {
    color: var(--color-gray3);
  }

  @media (max-width: 1024px) {
    width: 90%;
    padding: 4px 8px;
    margin-bottom: 6px;
  }
`;

const ItemQuantityInput = styled.input`
  width: 52px;
  height: 28px;
  border: solid 1px var(--color-gray3);
  font-family: Univers;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background-color: white;

  &::placeholder {
    color: var(--color-gray3);
  }

  @media (max-width: 1024px) {
    width: 10%;
    padding: 4px 8px;
    margin-bottom: 8px;
  }
`;

const ValidationWarning = styled.div`
  width: 100%;
  color: var(--color-primary);
  font-family: Univers;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: center;
`;

const AddToCartButton = styled.button`
  font-family: Univers;
  width: 98%;
  height: 42px;
  font-size: 16px;
  font-weight: 500;
  align-self: flex-end;
  background-color: ${(props) =>
    props.disabled ? 'var(--color-gray4)' : 'var(--color-primary)'};
  color: ${(props) => (props.disabled ? 'var(--color-dark-teal)' : 'white')};
  border: unset;
  margin-left: auto;
  margin-right: auto;
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 1);
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? 'var(--color-gray4)' : 'var(--color-dark-red)'};
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const MobileQuickQuoteContainer = styled.div`
  padding: 12px 28px;
`;

const Footer = styled.div`
  margin-top: 22px;
  height: 100%;
  background-color: var(--color-gray4);
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignInWarningText = styled.p`
  color: var(--color-primary);
  font-weight: 600;
  font-size: 12px;
`;

const SignInWarningMessage = styled.p`
  font-size: 10px;
  color: #293a4f;
`;

const SignInButton = styled.button`
  font-family: Univers;
  width: 88px;
  height: 26px;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--color-primary);
  color: #fff;
  border: unset;
  cursor: pointer;
  margin-top: 12px;

  &:hover {
    background-color: var(--color-dark-red);
  }
`;

const QuickQuoteMenu = ({ mobile }) => {
  const { updateShoppingCartData, updateCartItemsNumber } =
    useContext(ApplicationContext);
  const [, setError] = useContext(ErrorModalContext);
  const { openModal } = useOktaAuthContext();
  const { authState } = useOktaAuth();

  const [batchDataFromInput, setBatchDataFromInput] = useState([
    { sap: '', quantity: '' },
  ]);
  const [buttonMessage, setButtonMessage] = useState('ADD TO CART');
  const [isDisabled, setIsDisabled] = useState(true);
  const [validationWarningList, setValidationWarningList] = useState([]);
  const [isInputValid, setIsInputValid] = useState(true);

  const {
    mutate: mutateShoppingCartList,
    isLoading: isLoadingBatchAddToShoppingCart,
  } = useMutation((data) => batchAddItemToShoppingCart(batchDataFromInput), {
    onError: (error) => {
      const errorCode = error.message.split('/');
      if (error.constructor.name === 'TypeError') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'Network connection lost. Please check your network connection and try again.',
        });
      } else if (errorCode[1] === '401') {
        openModal();
      } else if (errorCode[1] === '500') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
          errorCode: `#SC-5-${errorCode[1]}`,
        });
      } else {
        const formattedErrors = [];
        const errorObject = JSON.parse(error.message);

        for (const key in errorObject.errors) {
          if (errorObject.errors.hasOwnProperty(key)) {
            const errorArray = errorObject.errors[key];
            const index = parseInt(key.split('[')[1].split(']')[0]);
            const errorMessage = errorArray[0];
            formattedErrors.push({ index, errorMessage });
          }
        }
        setValidationWarningList(formattedErrors);
        setButtonMessage('Add to Cart');
        setIsInputValid(false);
      }
    },
    onSuccess: (response) => {
      setBatchDataFromInput([{ sap: '', quantity: '' }]);
      updateShoppingCartData(response);
      updateCartItemsNumber(response.length);
      setTimeout(() => {
        setButtonMessage('Items added');
        setTimeout(() => {
          setButtonMessage('ADD TO CART');
        }, 1500);
      }, 1500);
    },
  });

  const handleInputChange = (index, key, value) => {
    const updatedBatchData = [...batchDataFromInput];
    updatedBatchData[index] = { ...updatedBatchData[index], [key]: value };

    setBatchDataFromInput(updatedBatchData);
    setIsInputValid(true);
  };

  const handleSubmit = (e) => {
    mutateShoppingCartList(batchDataFromInput);
  };

  useEffect(() => {
    if (isLoadingBatchAddToShoppingCart) {
      setButtonMessage('Adding to Cart...');
    }
  }, [isLoadingBatchAddToShoppingCart]);

  useEffect(() => {
    const allFieldsFilled = batchDataFromInput.every(
      (item) =>
        item.sap !== undefined &&
        item.sap.trim() !== '' &&
        item.quantity !== undefined &&
        item.quantity.trim() !== '' &&
        item.quantity.trim() > 0
    );
    setIsDisabled(!allFieldsFilled);
  }, [batchDataFromInput]);

  return (
    <>
      <ErrorModal />
      {!mobile ? (
        <QuickQuoteMenuContainer>
          {authState?.isAuthenticated ? (
            <>
              <Title>Quick request</Title>
              <OuterContainer>
                {[...Array(5)].map((_, index) => (
                  <InputsContainer key={index}>
                    <ItemMaterialNumberInput
                      $isInvalid={
                        !isInputValid &&
                        validationWarningList.some(
                          (warning) => warning.index === index
                        )
                      }
                      value={batchDataFromInput[index]?.sap || ''}
                      placeholder="Material #"
                      onChange={(e) =>
                        handleInputChange(index, 'sap', e.target.value)
                      }
                    />
                    <ItemQuantityInput
                      value={batchDataFromInput[index]?.quantity || ''}
                      placeholder="Qty"
                      type="number"
                      min="1"
                      onChange={(e) =>
                        handleInputChange(index, 'quantity', e.target.value)
                      }
                    />
                  </InputsContainer>
                ))}
              </OuterContainer>
              {!isInputValid && (
                <ValidationWarning>Unknown Material Number</ValidationWarning>
              )}

              <AddToCartButton
                onClick={() => handleSubmit()}
                disabled={isLoadingBatchAddToShoppingCart || isDisabled}
              >
                {buttonMessage}
              </AddToCartButton>
            </>
          ) : (
            <SignInContainer>
              <SignInWarningText>Sign in Required</SignInWarningText>
              <SignInWarningMessage>
                Please sign into your account to access this feature
              </SignInWarningMessage>
              <SignInButton onClick={() => openModal()}>Sign In</SignInButton>
            </SignInContainer>
          )}
        </QuickQuoteMenuContainer>
      ) : (
        <>
          <MobileQuickQuoteContainer>
            {authState?.isAuthenticated ? (
              <>
                <Title>Quick request</Title>
                {[...Array(5)].map((_, index) => (
                  <InputsContainer key={index}>
                    <ItemMaterialNumberInput
                      $isInvalid={
                        !isInputValid &&
                        validationWarningList.some(
                          (warning) => warning.index === index
                        )
                      }
                      value={batchDataFromInput[index]?.sap || ''}
                      placeholder="Material #"
                      onChange={(e) =>
                        handleInputChange(index, 'sap', e.target.value)
                      }
                    />
                    <ItemQuantityInput
                      value={batchDataFromInput[index]?.quantity || ''}
                      placeholder="Qty"
                      type="number"
                      min="1"
                      onChange={(e) =>
                        handleInputChange(index, 'quantity', e.target.value)
                      }
                    />
                  </InputsContainer>
                ))}
                {!isInputValid && (
                  <ValidationWarning>Unknown Material Number</ValidationWarning>
                )}
                <AddToCartButton
                  onClick={() => handleSubmit()}
                  disabled={isLoadingBatchAddToShoppingCart || isDisabled}
                >
                  {buttonMessage}
                </AddToCartButton>
              </>
            ) : (
              <SignInContainer>
                <SignInWarningText>Sign in Required</SignInWarningText>
                <SignInWarningMessage>
                  Please sign into your account to access this feature
                </SignInWarningMessage>
                <SignInButton onClick={() => openModal()}>SIGN IN</SignInButton>
              </SignInContainer>
            )}
          </MobileQuickQuoteContainer>
          <Footer />
        </>
      )}
    </>
  );
};

export default QuickQuoteMenu;
