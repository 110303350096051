import { OktaAuth } from '@okta/okta-auth-js';
import OktaSignIn from '@okta/okta-signin-widget';
import * as siteConfig from '../siteconfig';
import '../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const Logo = require('../../media/images/halliburton-logo.png');
import '../../css/app.css';

export const oktaAuth = new OktaAuth({
  issuer: siteConfig.issuer,
  debug: true,
  pkce: true,
  clientId: siteConfig.clientId,
  redirectUri: window.location.origin + '/login',
  postLogoutRedirectUri: window.location.origin + '/logout',
  scopes: ['openid', 'profile', 'email', 'address', 'phone', 'offline_access'],
  // audience: 'api://hct-kaiju-app',
  tokenManager: {
    autoRenew: true,
  },
  storageManager: {
    token: {
      storageTypes: ['cookie', 'localStorage'],
      useSeparateCookies: true,
    },
  },
  cookies: {
    secure: true,
  },
});

const oktaBaseURL = new URL(siteConfig.issuer).origin;

export const oktaSignIn = new OktaSignIn({
  logo: Logo,
  logoText: 'Halliburton',
  brandName: 'Halliburton',
  authClient: oktaAuth,
  colors: {
    brand: '#CC0100',
  },

  baseUrl: oktaBaseURL,
  issuer: siteConfig.issuer,
  clientId: siteConfig.clientId,
  redirectUri: window.location.origin + '/login',
  useClassicEngine: !siteConfig.useIdentityEngine,
  language: 'en',
  features: {
    registration: false,
    multiOptionalFactorEnroll: true,
    mfa: true,
  },
  /*
  idps: [
    {
      type: 'MICROSOFT',
      id: siteConfig.oktaIdpsMicrosoft,
      text: 'Sign in with Halliburton',
    },
  ],
  */
  prompt: 'login', // * this seems to make MFA work
  idpDiscovery: {
    requestContext: '/login',
  },
  helpLinks: {
    custom: [
      {
        text: 'For assistance, contact the Halliburton IT Service Center',
        href: 'mailto:globalITSC@halliburton.com',
      },
    ],
  },
});

oktaSignIn.on('afterError', function (context, error) {
  console.error(
    `Okta Sign In Widget Error\nName: ${error}\nMessage: ${error.message}\nStatus Code: ${error.statusCode}\nController: ${context.controller}`
  );
});
