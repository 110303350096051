import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import { ChevronUp, ChevronDown } from 'react-feather';

import ApplicationContext from '../../../ApplicationContext.js';

import FavoritesToggleButton from '../../Favorites/FavoritesToggleButton.jsx';
import UpdateItemInCartForm from '../../ToolDetailsPage/UpdateItemInCartForm.jsx';

const ProductNameAndFavoritesButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin-top: 10px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: start;
  }
`;

const FavoritesToggleAndMoreButtonMobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
`;

const FavoritesToggleButtonMobile = styled(FavoritesToggleButton)`
  width: auto;
  padding: 0;
  margin-left: 0 !important;
  margin-top: 0 !important;
`;

const RowContainer = styled.div`
  font-family: Univers;
  border-top: 1px solid var(--color-gray4);
  width: 100%;
  display: flex;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Row = styled.div`
  font-family: Univers;
  width: 100%;
  padding: 0px;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0 24px 18px;
  flex-grow: 1;

  @media (max-width: 1024px) {
    margin: 0px;
    font-size: 12px;
  }
`;

const FeatureNameAndDescriptionContainer = styled.div`
  display: flex;
  line-height: 22px;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 18px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
`;

const ColumnOne = styled.div`
  width: 40%;
  margin-right: 8px;
`;

const ColumnTwo = styled.div`
  width: 40%;
  margin-right: 18px;
`;

const ToolNameButton = styled.button`
  font-family: Univers;
  color: var(--color-gray1);
  font-weight: 500;
  font-size: 18px;
  font-style: ${(props) => props.$isMissingName && 'italic'};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;

  &:hover {
    color: var(--color-primary);
    text-decoration: underline;
  }

  &:first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 1024px) {
    font-weight: 600;
    font-size: 12px;
  }
`;

const FeatureName = styled.div`
  width: ${(props) => (props.$isExpanded ? '30vw' : '12vw')};
  color: var(--color-gray2);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 6px;

  @media (max-width: 1024px) {
    width: unset;
    text-align: right;
  }
`;

const FeatureDescription = styled.div`
  width: ${(props) => (props.$isExpanded ? '20vw' : '16vw')};
  color: var(--color-gray1);
  word-break: break-word;
  text-transform: uppercase;
  font-style: ${(props) => (!props.$isEmpty ? 'italic' : '')};
  font-size: 14px;
  display: ${(props) => (!props.$isMore ? '' : 'flex')};
  align-items: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1024px) {
    width: unset;
  }
`;

const MoreLessButton = styled.button`
  font-family: Univers;
  display: flex;
  align-items: center;
  align-self: ${(props) => (props.$isLess ? 'end' : 'baseline')};
  line-height: ${(props) => !props.$isLess && '1.7;'}
  padding-bottom: 10px;
  color: var(--color-primary);
  font-size: 12x;
  font-weight: 500;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const StyledChevronDown = styled(ChevronDown)`
  margin-top: 2px;
`;

const StyledChevronUp = styled(ChevronUp)`
  margin-top: 2px;
`;

const ToolsContainer = styled.div`
  display: flex;
  width: 75%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const FavoritesAndUpdateShoppingCartContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const UpdateItemInCartAndMoreButtonContainer = styled.div`
  margin-top: 35px;
  margin-bottom: 0px;
  padding: 0px 4px;
  box-sizing: border-box;
  min-width: 232px;
  width: 25%;
  @media (max-width: 1024px) {
    margin-top: 8px;
    margin-bottom: 15px;
    padding: 0px;
    display: flex;
    justify-content: end;
  }
`;

const MobileMoreLessButton = styled.button`
  font-family: Univers;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const MobileContainer = styled.div`
  display: grid;
  grid-template-columns: 16% 30%;
  column-gap: 8px;
  margin-bottom: 6px;

  @media (max-width: 400px) {
    grid-template-columns: 40% 55%;
  }
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 1px solid black;
  padding: 1px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
  margin-top: 6px;
`;

const Checkbox = ({ isDisabled, onToggle }) => {
  const handleCheck = (e) => {
    onToggle(e);
  };

  return <StyledCheckbox onChange={handleCheck} disabled={isDisabled} />;
};

const SearchResultsToolRow = ({
  searchData,
  isFavorited,
  refetchFavoritesData,
  oktaRedirectOnLoginTo,
  comparisonArr,
  setComparisonArr,
  toolComparisonActive,
}) => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);
  const [isMobileRowExpanded, setIsMobileRowExpanded] = useState(false);

  const { classType } = useContext(ApplicationContext);

  const navigate = useNavigate();

  const capitalizeWords = (str) => {
    return str?.toLowerCase().replace(/(?:^|\s)\w/g, (match) => {
      return match.toUpperCase();
    });
  };

  const redirectToToolDetailsPage = () => {
    navigate(`/tool/${searchData.SapNumber}`);
  };

  const onMoreLessClick = () => {
    setIsPanelExpanded((prev) => !prev);
  };

  const onMobileMoreLessButtonClick = () => {
    setIsMobileRowExpanded((prev) => !prev);
  };

  const location = useLocation();
  const currentPath = location.pathname;
  const fullPath = currentPath + location.search;
  const isMobile = classType === 'compact';

  const handleToggle = (e) => {
    const newArr = [...comparisonArr];

    if (e.target.checked === false) {
      const index = newArr.indexOf(searchData.SapNumber);
      if (index > -1) newArr.splice(index, 1);
    } else if (e.target.checked && !newArr.includes(searchData.SapNumber)) {
      newArr.push(searchData.SapNumber);
    }

    setComparisonArr(newArr);
  };

  const isDisabled = comparisonArr.length >= 4;

  return (
    <RowContainer>
      <Row>
        {isMobile ? (
          <div>
            <FavoritesToggleAndMoreButtonMobileContainer>
              <FavoritesToggleButtonMobile
                pagePath={fullPath}
                sapNumber={searchData.SapNumber}
                isFavorited={isFavorited}
                refetchFavoritesData={refetchFavoritesData}
                marginLeft
              />
              <MobileMoreLessButton onClick={onMobileMoreLessButtonClick}>
                <div>{isMobileRowExpanded ? 'Less' : 'More'}</div>
                {isMobileRowExpanded ? (
                  <StyledChevronUp strokeWidth={3} height={18} />
                ) : (
                  <StyledChevronDown strokeWidth={3} height={18} />
                )}
              </MobileMoreLessButton>
            </FavoritesToggleAndMoreButtonMobileContainer>

            <CheckboxContainer>
              {toolComparisonActive && (
                <Checkbox
                  sapNumber={searchData.sapNumber}
                  isDisabled={isDisabled}
                  onToggle={handleToggle}
                />
              )}
              <ToolNameButton onClick={redirectToToolDetailsPage}>
                {searchData.Name || 'Tool Name N/A'}
              </ToolNameButton>
            </CheckboxContainer>
          </div>
        ) : (
          <ProductNameAndFavoritesButtonContainer>
            {toolComparisonActive && (
              <Checkbox
                sapNumber={searchData.sapNumber}
                isDisabled={isDisabled}
                onToggle={handleToggle}
              />
            )}
            <ToolNameButton
              $isMissingName={!searchData.Name}
              onClick={redirectToToolDetailsPage}
            >
              {searchData.Name || 'Tool Name N/A'}
            </ToolNameButton>{' '}
            <FavoritesToggleButton
              pagePath={fullPath}
              sapNumber={searchData.SapNumber}
              isFavorited={isFavorited}
              refetchFavoritesData={refetchFavoritesData}
            />
          </ProductNameAndFavoritesButtonContainer>
        )}
        <FavoritesAndUpdateShoppingCartContainer>
          <ToolsContainer>
            <DescriptionContainer $isExpanded={isPanelExpanded}>
              <>
                {isPanelExpanded && !isMobile ? (
                  <ColumnContainer>
                    <ColumnOne>
                      <FeatureNameAndDescriptionContainer>
                        <FeatureName $isExpanded>Material Number </FeatureName>
                        <FeatureDescription
                          $isExpanded
                          $isEmpty={!!searchData.SapNumber}
                        >
                          {searchData.SapNumber}{' '}
                        </FeatureDescription>
                      </FeatureNameAndDescriptionContainer>
                      <FeatureNameAndDescriptionContainer>
                        <FeatureName $isExpanded>Legacy Number </FeatureName>
                        <FeatureDescription
                          $isExpanded
                          $isEmpty={!!searchData.LegacyNumber}
                        >
                          {searchData.LegacyNumber || 'N/A'}{' '}
                        </FeatureDescription>
                      </FeatureNameAndDescriptionContainer>
                      {searchData.DescriptionLite.slice(0, 4).map(
                        (description) => (
                          <React.Fragment
                            key={searchData.SapNumber + description.Name}
                          >
                            <FeatureNameAndDescriptionContainer>
                              <FeatureName $isExpanded>
                                {capitalizeWords(description.Name)}
                              </FeatureName>
                              <FeatureDescription
                                $isExpanded
                                $isEmpty={!!description.Value}
                              >
                                {description.Value || 'N/A'}
                              </FeatureDescription>
                            </FeatureNameAndDescriptionContainer>
                          </React.Fragment>
                        )
                      )}
                    </ColumnOne>
                    <ColumnTwo>
                      {searchData.DescriptionLite.slice(4, 11).map(
                        (description, index) => (
                          <React.Fragment
                            key={searchData.SapNumber + description.Name}
                          >
                            <FeatureNameAndDescriptionContainer>
                              <FeatureName $isExpanded $isSecondColumn>
                                {capitalizeWords(description.Name)}
                              </FeatureName>
                              <FeatureDescription
                                $isExpanded
                                $isSecondColumn
                                $isEmpty={!!description.Value}
                              >
                                {description.Value || 'N/A'}
                              </FeatureDescription>
                            </FeatureNameAndDescriptionContainer>
                          </React.Fragment>
                        )
                      )}
                    </ColumnTwo>
                    {isPanelExpanded && (
                      <MoreLessButton $isLess onClick={onMoreLessClick}>
                        <div>- Less</div>
                      </MoreLessButton>
                    )}
                  </ColumnContainer>
                ) : (
                  <>
                    {isMobile ? (
                      <MobileContainer>
                        {' '}
                        <FeatureName>Material Number </FeatureName>
                        <FeatureDescription
                          $isEmpty={!!searchData.SapNumber}
                          $isMore
                        >
                          {searchData.SapNumber}{' '}
                        </FeatureDescription>
                        <FeatureName>Legacy Number </FeatureName>
                        <FeatureDescription
                          $isEmpty={!!searchData.LegacyNumber}
                        >
                          {searchData.LegacyNumber || 'N/A'}{' '}
                        </FeatureDescription>
                        {isMobileRowExpanded
                          ? searchData.DescriptionLite.map((description) => (
                              <React.Fragment
                                key={searchData.SapNumber + description.Name}
                              >
                                <FeatureName>
                                  {capitalizeWords(description.Name)}
                                </FeatureName>
                                <FeatureDescription
                                  $isEmpty={!!description.Value}
                                >
                                  {description.Value || 'N/A'}
                                </FeatureDescription>
                              </React.Fragment>
                            ))
                          : searchData.DescriptionLite.slice(0, 2).map(
                              (description) => (
                                <React.Fragment
                                  key={searchData.SapNumber + description.Name}
                                >
                                  <FeatureName>
                                    {capitalizeWords(description.Name)}
                                  </FeatureName>
                                  <FeatureDescription
                                    $isEmpty={!!description.Value}
                                  >
                                    {description.Value || 'N/A'}
                                  </FeatureDescription>
                                </React.Fragment>
                              )
                            )}
                      </MobileContainer>
                    ) : (
                      <>
                        <FeatureNameAndDescriptionContainer>
                          <FeatureName>Material Number </FeatureName>
                          <FeatureDescription
                            $isEmpty={!!searchData.SapNumber}
                            $isMore
                          >
                            {searchData.SapNumber}{' '}
                          </FeatureDescription>
                        </FeatureNameAndDescriptionContainer>
                        <FeatureNameAndDescriptionContainer>
                          <FeatureName>Legacy Number </FeatureName>
                          <FeatureDescription
                            $isEmpty={!!searchData.LegacyNumber}
                          >
                            {searchData.LegacyNumber || 'N/A'}{' '}
                          </FeatureDescription>
                        </FeatureNameAndDescriptionContainer>
                        {searchData.DescriptionLite.slice(
                          0,
                          isMobile ? 2 : 4
                        ).map((description) => (
                          <React.Fragment
                            key={searchData.SapNumber + description.Name}
                          >
                            <FeatureNameAndDescriptionContainer>
                              <FeatureName>
                                {capitalizeWords(description.Name)}
                              </FeatureName>
                              <FeatureDescription
                                $isEmpty={!!description.Value}
                              >
                                {description.Value || 'N/A'}
                              </FeatureDescription>
                            </FeatureNameAndDescriptionContainer>
                          </React.Fragment>
                        ))}
                        {!isMobile && (
                          <MoreLessButton onClick={onMoreLessClick}>
                            <div>More +</div>
                          </MoreLessButton>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </DescriptionContainer>
          </ToolsContainer>
          <UpdateItemInCartAndMoreButtonContainer>
            <UpdateItemInCartForm
              toolData={searchData}
              buttonWidth={'126px'}
              buttonHeight={'32px'}
              oktaRedirectOnLoginTo={oktaRedirectOnLoginTo}
            />
          </UpdateItemInCartAndMoreButtonContainer>
        </FavoritesAndUpdateShoppingCartContainer>
      </Row>
    </RowContainer>
  );
};

export default SearchResultsToolRow;
